
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { DEFAULT_ICON_COLOR, DEFAULT_ICON_WIDTH } from '@pixcap/ui-library/constants/theme.constants';

	@Component({
		name: 'IconCloudArrowUp',
	})
	export default class IconCloudArrowUp extends Vue {
		@Prop({ default: DEFAULT_ICON_WIDTH }) readonly width: number;
		@Prop() readonly height: number;
		@Prop({ default: DEFAULT_ICON_COLOR }) readonly fill: string;
	}


	import { Vue, Component, Prop } from 'vue-property-decorator';
	import { DEFAULT_ICON_COLOR } from '@pixcap/ui-library/constants/theme.constants';

	@Component({
		name: 'IconCrownSimple',
	})
	export default class IconCrownSimple extends Vue {
		@Prop() readonly width: number;
		@Prop() readonly height: number;
		@Prop({ default: DEFAULT_ICON_COLOR }) readonly fill: string;
	}


	import { Component, Vue, Prop } from 'vue-property-decorator';

	@Component({
		name: 'IconCircleNotch',
	})
	export default class IconCircleNotch extends Vue {
		@Prop({ default: 40 }) readonly size: number;
		@Prop({ default: 'fill-primary-6' }) readonly fill: string;
	}

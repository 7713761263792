
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { DEFAULT_ICON_COLOR, DEFAULT_ICON_WIDTH } from '@pixcap/ui-library/constants/theme.constants';

	@Component({
		name: 'IconCrown',
	})
	export default class IconCrown extends Vue {
		@Prop({ default: false }) readonly isOutline: boolean;
		@Prop({ default: DEFAULT_ICON_WIDTH }) readonly width: number;
		@Prop() readonly height: number;
		@Prop() readonly isGradientFill: boolean;
		@Prop({ default: DEFAULT_ICON_COLOR }) readonly fill: string;

		gradientFillId = `icon-crwon-fill-id-${new Date().getTime()}`;

		get computedFill() {
			return this.isGradientFill ? `url(#${this.gradientFillId})` : this.fill;
		}
	}
